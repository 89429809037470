import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// Components
import Layout from 'components/Layout'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'

type IndexPageProps = {
    data: {
        pageSeo: ISeoQuery
    }
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
    const pageSeo = data.pageSeo
    const { image } = pageSeo
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }

    return (
        <Layout seo={seo}>
            {image && (
                <GatsbyImage image={image.gatsbyImageData} alt="Nosotros" />
            )}
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query AboutPageQuery {
        pageSeo: contentfulSeo(slug: { eq: "about" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`
